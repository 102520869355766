export const columns = [
  {
    label: "Timestamp",
    field: "timestamp",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Touchpoint ID",
    field: "touchpoint_id",
  },
  {
    label: "ISMS",
    field: "isms_code",
  },
  {
    label: "Action",
    field: "action",
  },
  {
    label: "FA Code",
    field: "product_code",
  },
  {
    label: "Barcode",
    field: "barcode",
  },
  {
    label: "Scan Type",
    field: "scan_type",
  },
  {
    label: "Questionaire?",
    field: "questionaire",
  },
  {
    label: "Point",
    field: "point",
    type: "number",
  },
  {
    label: "Answers",
    field: "answers",
    sortable: false,
  },
  {
    label: "IP Address",
    field: "ip_address",
  },
  {
    label: "Production date",
    field: "gen_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Expiration date",
    field: "exp_date",
    dateInputFormat: "yyyy-MM-dd HH:mm:ss",
    dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
  },
  {
    label: "Expiration Duration (day)",
    field: "exp_days",
    type: "number",
  },
  {
    label: "Expiration Campaign ID",
    field: "exp_campaign_id",
  },
];
